export const environment = {
  production: true,
  enableDebug: false,
  env: "NEXT",
  appVersion: "1.18.35-dev-3c4a1240",
  apiUrl: "https://api-next-v1.eva-it.ch/api",
  googleAnalyticsId: "G-ZDJM8MTSEW",
  hotjarId: null,
  clarityId: "jyiybminl9",
  intercomId: "jdgxwp9j",
  sfLicense: "Ngo9BigBOggjHTQxAR8/V1NGaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZjUX5acHdURWFaUkFyWw==",
};
